import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"

import Seo from "@components/Seo"
import VideoHero from "@components/sections/heros/VideoHero"
import LatestBlogPost from "@components/sections/LatestBlogPosts"
import MeetTheTeam from "@components/sections/MeetTheTeam"
import OurLocations from "@components/sections/OurLocations"
import OurProcessSection from "@components/sections/OurProcessSection"
import WhoAreWe from "@components/sections/WhoAreWe"

export default function AboutUsPage({ data }) {
  const page = data.allWpPage.nodes[0]
  const {
    heroData,
    ourProcess,
    ourProcessContent,
    whoAreWe,
  } = page.acfAboutFields

  return (
    <>
      <Seo {...page.seo} />
      <Helmet
        bodyAttributes={{
          class: "short",
        }}
      />

      <VideoHero pageTitle={page.title} {...heroData} />
      <div style={{ overflow: "hidden" }}>
        <WhoAreWe {...whoAreWe} />
        <OurProcessSection
          ourProcess={ourProcess}
          ourProcessContent={ourProcessContent}
        />
        <OurLocations />
        <MeetTheTeam />
        <LatestBlogPost />
      </div>
    </>
  )
}

export const query = graphql`query ($slug: String!) {
  allWpPage(
    filter: {template: {templateName: {eq: "About Us"}}, slug: {eq: $slug}}
  ) {
    nodes {
      id
      uri
      title
      link
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FIXED)
            }
          }
        }
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        schema {
          raw
        }
      }
      acfAboutFields {
        heroData {
          title
          vimeoLink
          backgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
              }
            }
          }
        }
        ourProcess {
          content
          title
        }
        ourProcessContent {
          content
          title
        }
        whoAreWe {
          content
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 480
                  height: 480
                  transformOptions: {cropFocus: CENTER}
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    }
  }
}
`
