import React from "react"
import PropTypes from "prop-types"
import MapLocationModule from "../modules/MapLocation"
import { graphql, useStaticQuery } from "gatsby"
import Heading from "@components/ui/Heading"

const OurLocations = ({ title, officeLocations }) => {
  const data = useStaticQuery(graphql`
    fragment backgroundImageFixed on WpMediaItem {
      localFile {
        childImageSharp {
          gatsbyImageData(
            width: 419
            transformOptions: { fit: COVER, cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
    }

    {
      wp {
        siteDetails {
          locations {
            ourLocations {
              address
              map {
                latitude
                longitude
              }
              phoneNumber
              name
              backgroundImage {
                ...backgroundImageFixed
              }
            }
          }
        }
      }
    }
  `)

  const locations = data.wp.siteDetails.locations.ourLocations

  if (!locations) return <></>

  return (
    <section id="our_locations_section">
      <div id="our_locations" className="container">
        <Heading node="h2" underlined>
          {title}
        </Heading>

        <div id="our_locations">
          {locations.map((location, key) => {
            return <MapLocationModule key={key} index={key + 1} {...location} />
          })}
        </div>
      </div>
    </section>
  )
}

OurLocations.propTypes = {
  title: PropTypes.string,
}

OurLocations.defaultProps = {
  title: `Our locations`,
}

export default OurLocations
