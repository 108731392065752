import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import ProcessModule from "../modules/ProcessModule"
import Colors from "@styles/variables/Colors"
import Heading from "@components/ui/Heading"
import Prose from "@components/ui/prose"

const OurProcessStyle = styled.section`
  margin: 10px 0 70px 0;

  ${breakpointsMedia(breakpoints.tabletWide)} {
    margin: 50px 0 70px 0;
  }

  .process-section-content {
    width: 1038px;
    margin: auto;
    max-width: 100%;
    position: relative;
    z-index: 1;
  }

  .process-modules {
    display: flex;
    flex-direction: column;

    padding-top: 50px;
    ${breakpointsMedia(breakpoints.tabletWide)} {
      padding-top: 101px;
    }

    &:before {
      content: "";
      background-color: ${Colors.offWhite};
      height: 230vw;
      display: block;
      position: absolute;
      width: 70vw;
      top: 50%;
      transform: translate(-50%, -50%) skewX(42deg);
      z-index: 0;
      pointer-events: none;
      left: 47%;
    }
  }
`

const OurProcessSection = ({ ourProcess, ourProcessContent }) => {
  return (
    <OurProcessStyle>
      <div className="container">
        <div className="process-section-content">
          <div className="text-center">
            {ourProcessContent.title && (
              <Prose>
                <Heading node="h2" data-aos="fade" data-aos-duration="1000">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ourProcessContent.title,
                    }}
                  />
                </Heading>
              </Prose>
            )}

            {ourProcessContent.content && (
              <div
                className="content  "
                data-aos="fade"
                data-aos-duration="1000"
                data-aos-delay="200"
                dangerouslySetInnerHTML={{ __html: ourProcessContent.content }}
              />
            )}
          </div>
        </div>

        <div className="process-modules">
          {ourProcess.map((node, key) => {
            const indexNumber = key + 1
            return (
              <ProcessModule
                key={key}
                indexNumber={indexNumber}
                styleIndex={indexNumber}
                {...node}
              />
            )
          })}
        </div>
      </div>
    </OurProcessStyle>
  )
}

OurProcessSection.propTypes = {
  title: PropTypes.string,
  ourProcessContent: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
  }),
  ourProcess: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
    })
  ),
}

OurProcessSection.defaultProps = {
  title: "Our Process",
}

export default OurProcessSection
