import React from "react"
import PropTypes from "prop-types"
import { HeroStyle } from "@styles/organisms/Hero"
import Breadcrumbs from "@components/ui/Breadcrumbs"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { breakpoints } from "@styles/variables/Breakpoint"
import Heading from "@components/ui/Heading"

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

const Frame = ({ src, width, height }) => (
  <iframe
    title="vimeo video"
    src={src}
    width={width}
    height={height}
    allow="autoplay"
    scrolling="no"
  />
)

const VideoHeroStyle = styled(HeroStyle)`
  background-color: var(--color-bg-shade);

  .container {
    min-height: 698px;
    height: 698px;

    @media (min-width: ${breakpoints.tablet}) {
      &:before {
        content: "";
        width: 100vw;
        position: absolute;
        top: 0;
        left: 100%;
        height: 100%;
        transform-origin: bottom;
        z-index: -1;
        background-color: var(--color-secondary);
      }
    }
  }

  #hero_video {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    &:before {
      content: "";
      background-color: var(--color-bg-shade);
      width: 76%;
      position: absolute;
      top: 0;

      height: 100%;
      transform: skewX(45deg);
      transform-origin: bottom;
      transition: all 1s;
      z-index: 4;
      right: 86%;
      left: auto;

      @media (min-width: ${breakpoints.tablet}) {
        left: 0;
        right: auto;
      }
    }

    &:after {
      content: "";
      background-color: var(--color-bg-shade);
      width: 200vw;
      position: absolute;
      top: 0;
      left: 120%;
      height: 100%;
      transform: skewX(45deg);
      transform-origin: bottom;
      transition: all 1s;

      @media (min-width: ${breakpoints.tablet}) {
        width: 100vw;
      }
    }

    iframe {
      width: 100%;
      height: 100%;
      opacity: 0;
      visibility: hidden;
      transition: all 1s;
    }
  }

  #close-video-btn {
    position: absolute;
    top: 2%;
    left: 5%;
    z-index: 5;
    color: var(--color-text-light-off);
    font-size: 2rem;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: all 1s;

    i {
      text-shadow: 3px 3px 0 rgba(0, 0, 0, 0.3);
    }
  }

  .play-video-btn-wrap {
    transition: all 1s;
    z-index: 5;
    margin-top: 33px;

    @media (min-width: ${breakpoints.tablet}) {
      margin-top: 0;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }

    #play-video-btn {
      height: 82.19px;
      width: 82.19px;
      border-radius: 100%;
      background-color: var(--color-primary);
      position: relative;
      cursor: pointer;
      transition: all 0.25s;
      animation: glowBoxShadow 1.5s both infinite;
      margin: auto;

      @media (min-width: ${breakpoints.tablet}) {
        margin: auto;
        height: 130.13px;
        width: 130.13px;
      }

      &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 18px 0 18px 26px;
        border-color: transparent transparent transparent #fff;

        top: 50%;
        left: 50%;
        transform: translate(-29%, -50%);

        @media (min-width: ${breakpoints.tablet}) {
          border-width: 29px 0 29px 41px;
        }
      }

      .no-touch &:hover {
        background-color: var(--color-primary-darkened);
      }
    }
  }

  .hero-image {
    height: 70%;
    width: 100%;
    position: absolute !important;
    left: 0;
    bottom: 0;
    top: auto;

    @media (min-width: ${breakpoints.tablet}) {
      top: 0;
      bottom: auto;
      height: 100%;
    }
  }

  .container {
    z-index: 11;
    position: relative;
    display: flex;

    .inner {
      display: flex;
      justify-content: center;
      flex: 1;

      @media (min-width: ${breakpoints.tablet}) {
        align-items: center;
        justify-content: flex-start;
      }
    }

    .breadcrumbs {
      position: absolute;
      bottom: 100%;
      left: 0;

      @media (max-width: ${breakpoints.phablet}) {
        display: none;
      }
    }
  }

  .hero-content {
    z-index: 5;
    margin-top: 14%;
    text-align: left;

    @media (min-width: ${breakpoints.tablet}) {
      margin-top: 0;
      width: 463px;
    }

    h1 {
      text-align: left;
    }

    .content {
      transition: all 1s;
      position: relative;
    }
  }

  .hero-video-open & {
    #hero_video {
      background-color: var(--color-bg-shade);

      &:before {
        left: -100%;
      }

      &:after {
        left: 150%;
        opacity: 0;
        visibility: hidden;
      }
    }

    .container {
      &:before {
        left: 150%;
      }
    }

    .hero-content {
      pointer-events: none;
    }

    .hero-content .content {
      transform: translate(-50%, 0);
      opacity: 0;
      visibility: hidden;
    }

    .play-video-btn-wrap {
      transform: translate(50%, -50%);
      opacity: 0;
      visibility: hidden;
    }

    #hero_video {
      iframe {
        opacity: 1;
        visibility: visible;
        border: none;
      }
    }

    #close-video-btn {
      opacity: 1;
      visibility: visible;
    }
  }
`

class VideoHero extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      videoOpen: false,
    }

    this.openVideo = this.openVideo.bind(this)
    this.closeVideo = this.closeVideo.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
  }

  handleScroll() {
    if (this.state.videoOpen) {
      this.closeVideo()
    }
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", this.handleScroll)
    }
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("scroll", this.handleScroll)
    }
  }

  openVideo() {
    if (this.state.videoOpen) {
      return
    }

    document.body.classList.add("hero-video-open")
    this.setState({
      videoOpen: true,
    })
  }

  closeVideo() {
    if (!this.state.videoOpen) {
      return
    }

    document.body.classList.remove("hero-video-open")
    this.setState({
      videoOpen: false,
    })
  }

  render() {
    const { pageTitle, title, backgroundImage, vimeoLink } = this.props

    const breadcrumbs = [
      {
        title: pageTitle,
      },
    ]

    const autoPlayLink = `${vimeoLink}?autoplay=1`

    const imageData = backgroundImage.localFile?.childImageSharp.gatsbyImageData

    return (
      <VideoHeroStyle>
        <div style={{ overflow: "hidden" }}>
          <div className="container">
            <div className="inner">
              <GatsbyImage image={imageData} Tag="div" className="hero-image" />
              <div className="hero-content">
                <div className="content">
                  <Breadcrumbs crumbs={breadcrumbs} />
                  <Heading
                    node="h1"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="400"
                    underlined
                  >
                    {title}
                  </Heading>
                </div>

                {vimeoLink && (
                  <div className="play-video-btn-wrap" onClick={this.openVideo}>
                    <div
                      id="play-video-btn"
                      data-video-play=""
                      data-aos="fade-left"
                      data-aos-duration="1000"
                      data-aos-delay="400"
                      className=" "
                    >
                      <i />
                    </div>
                  </div>
                )}
              </div>

              {vimeoLink && (
                <div id="close-video-btn" onClick={this.closeVideo}>
                  <i className="fas fa-long-arrow-left" />
                </div>
              )}

              {vimeoLink && (
                <div id="hero_video" className="hero-video">
                  {this.state.videoOpen && <Frame src={autoPlayLink} />}
                </div>
              )}
            </div>
          </div>
        </div>
      </VideoHeroStyle>
    )
  }
}

VideoHero.propTypes = {
  pageTitle: PropTypes.string,
  title: PropTypes.string,
  vimeoLink: PropTypes.string,
  backgroundImage: PropTypes.object,
}

export default VideoHero
