import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import { GlobalClientLogoGallery } from "./GlobalClientLogoGallery"
import Heading from "@components/ui/Heading"
import Spacer from "@components/ui/Spacer"

const WhoWeAreStyle = styled.section`
  padding: 60px 0 10px 0;
  position: relative;
  z-index: 1;
  --con_width: 522px;

  @media (min-width: ${breakpoints.tablet}) {
    border-bottom: 1px solid var(--color-secondary);
    padding: 60px 0;
  }

  #who_are_we_wrap + #portfolio_container_gallery {
    margin-top: 20px;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      margin-top: 89px;
    }
  }

  .img-content-block {
    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: column;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      flex-direction: row;
    }
  }

  .image-bg-wrap {
    width: var(--con_width);
    margin-bottom: 33px;

    @media (min-width: ${breakpoints.tablet}) {
      flex: 0 0 var(--con_width);
    }

    ${breakpointsMedia(breakpoints.tabletWide)} {
      margin-right: 33px;
      margin-bottom: 0;
    }
  }

  .image-bg-triangle {
    position: relative;
    max-width: 100%;
    padding-bottom: 100%;
    transform-style: preserve-3d;

    @media (min-width: ${breakpoints.tablet}) {
      padding-bottom: 0;
      height: var(--con_width);
    }

    ${breakpointsMedia(breakpoints.tabletWide)} {
      &:before {
        content: "";
        border-style: solid;
        border-width: var(--con_width) 0 0 var(--con_width);
        border-color: transparent transparent transparent var(--color-primary);
        bottom: 0;
        left: 0;

        z-index: -1;
        position: absolute;
        transition: all 1s;
      }
    }

    .image-wrap {
      height: 100%;
      position: absolute;
      width: 100%;
      padding: 50px;
      transform: translateZ(50px);

      ${breakpointsMedia(breakpoints.tabletWide)} {
        position: relative;
        padding: 33px;
      }
    }

    .image {
      background-size: cover;
      background-position: center;
      height: 100%;
    }
  }

  .content-wrap {
    flex: 1;

    .content {
      font-size: 17px;
      line-height: 27px;
    }
  }
`

const WhoAreWe = ({ title, content, image }) => {
  const fluid = image.localFile?.childImageSharp?.gatsbyImageData

  return (
    <WhoWeAreStyle>
      <div id="who_are_we_wrap">
        <div className="container">
          <div data-aos="fade" data-aos-duration="1000">
            <Heading node="h2" underlined>
              {title}
            </Heading>
            <Spacer y="sm" />
          </div>

          <div className="img-content-block">
            {fluid && (
              <div
                className="image-bg-wrap  "
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <div className="image-bg-triangle">
                  <div className="image-wrap">
                    <div className="image">
                      <GatsbyImage image={fluid} />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div
              className="content-wrap  "
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
          </div>
        </div>
      </div>

      <GlobalClientLogoGallery />
    </WhoWeAreStyle>
  )
}

WhoAreWe.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.object,
}

WhoAreWe.defaultProps = {
  title: `Who are we?`,
}

export default WhoAreWe
