/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import Colors from "@styles/variables/Colors"
import { getSrc } from "gatsby-plugin-image"

//TODO: setup all SEO meta
function Seo({ children, ...rest }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const {
    lang,
    meta,
    title,
    canonical,
    twitterTitle,
    twitterDescription,

    metaDesc,
    metaKeywords,
    metaRobotsNofollow,
    metaRobotsNoindex,
    opengraphAuthor,
    opengraphImage,
    opengraphImageSrc,
    opengraphDescription,
    opengraphModifiedTime,
    opengraphPublishedTime,
    opengraphPublisher,
    opengraphSiteName,
    opengraphTitle,
    opengraphType,
    opengraphUrl,
    schema,
    customScheme,
    customCanonical,
  } = rest

  const ogImageSrc =
    opengraphImageSrc ||
    (opengraphImage?.localFile &&
      process.env.GATSBY_SITE_URL + getSrc(opengraphImage.localFile))

  const metaDescription =
    opengraphDescription || metaDesc || site.siteMetadata.description
  const metaTitle = opengraphTitle || title

  const robotsIndex = process.env.GATSBY_NO_INDEX
    ? "noindex"
    : metaRobotsNoindex
  const robots = [robotsIndex, metaRobotsNofollow]

  const canon = canonical || customCanonical

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: metaKeywords,
        },
        {
          name: `robots`,
          content: robots && robots.join(", "),
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:site_name`,
          content: opengraphSiteName || site.title || "Passion Digital®",
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: ogImageSrc,
        },
        {
          property: `og:type`,
          content: opengraphType || `website`,
        },
        {
          name: `article:author`,
          content: opengraphAuthor,
        },
        {
          name: `article:publisher`,
          content: opengraphPublisher,
        },
        {
          name: `article:published_time`,
          content: opengraphPublishedTime,
        },
        {
          name: `article:modified_time`,
          content: opengraphModifiedTime,
        },
        {
          property: `og:url`,
          content: opengraphUrl,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: twitterTitle || title,
        },
        {
          name: `twitter:description`,
          content: twitterDescription || metaDescription,
        },
        {
          name: `msapplication-navbutton-color`,
          content: Colors.cardinal,
        },
        {
          name: `apple-mobile-web-app-capable`,
          content: "yes",
        },
        {
          name: `apple-mobile-web-app-status-bar-style`,
          content: "black-translucent",
        },
      ].concat(meta)}
    >
      {canon && <link rel="canonical" href={canon} />}
      {schema && (
        <script type="application/ld+json" className="yoast-schema-graph">
          {schema.raw}
        </script>
      )}
      {customScheme && (
        <script type="application/ld+json" className="custom-schema-graph">
          {customScheme}
        </script>
      )}
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
