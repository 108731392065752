import styled from "styled-components"
import { breakpoints, breakpointsMedia } from "../variables/Breakpoint"
import { FontFamily } from "../variables/FontFamily"
import { textShadowOffset } from "../helpers/Functions"

export const MapLocationStyle = styled.div`
  width: 480px;
  max-width: 100%;
  margin: auto auto 33px;
  position: relative;

  ${breakpointsMedia(breakpoints.tabletWide)} {
    width: 937px;
    margin: auto auto 50px;
  }

  .backdrop {
    display: block;
    width: 100%;
    position: absolute;
    height: 100%;
    z-index: -1;
    left: 10px;
    top: 10px;
    background-color: var(--color-primary);
  }

  .inner-wrap {
    display: flex;
    flex-direction: column;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      flex-direction: row;
    }
  }

  .location-details {
    min-height: 277px;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      height: 282px;
      width: 419px;
    }

    color: var(--color-text-light);
    font-family: ${FontFamily.Heading};
    text-align: center;
    background-color: var(--color-text-light);
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 0;
    position: relative;

    .location-overlay {
      background-color: rgba(0, 0, 0, 0.48);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    .inner {
      position: relative;
      padding: 0 15px;
    }

    .name {
      font-size: 60px;
      font-weight: 800;
      letter-spacing: 0;
      line-height: 1;
      text-align: center;
      font-family: ${FontFamily.Heading};
      text-transform: uppercase;
      margin-bottom: 20px;
      ${textShadowOffset("#FFFFFF", "#DB8F12")}
    }

    .address,
    .phone {
      font-size: 20px;
      font-weight: 500;
      line-height: 33px;
      color: var(--color-text-light);
      font-family: ${FontFamily.Heading};

      * {
        color: var(--color-text-light);
        font-family: ${FontFamily.Heading};
      }
    }

    .address {
      address {
        display: inline;
      }
    }

    .phone {
      white-space: nowrap;
      display: block;
    }
  }

  .backdrop {
    background-color: var(--color-secondary);
  }

  .map-wrap {
    position: relative;
    background: white;
    height: 282px;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      height: auto;
      flex: 1;
    }

    .google-map {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute !important;
    }

    .pin {
      height: 43.75px;
      width: 43.75px;
      background-color: var(--color-secondary);
      position: relative;
      border-radius: 100%;
      overflow: hidden;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      animation: pinGlowBoxShadow 1.5s both infinite;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        text-align: center;
        display: block;
        width: 12px;
        line-height: 43.75px;
        color: var(--color-text-light);
        position: relative;
        font-weight: 900;
      }
    }
  }

  &.location-2 {
    .name {
      ${textShadowOffset("#FFFFFF", "#B71B54")}
    }

    .backdrop {
      background-color: var(--color-primary);
    }

    .pin {
      background-color: var(--color-primary);
      animation: pinGlowBoxShadow2 1.5s both infinite;
    }
  }
`
