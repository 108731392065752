import React from "react"
import styled from "styled-components"
import {
  breakpoints,
  breakpointsMedia,
} from "@styles/variables/Breakpoint"
import { FontFamily } from "@styles/variables/FontFamily"

const ProcessModuleStyle = styled.div`
  --main-color: var(--color-primary);
  --shadow-color: var(--color-primary-darkened);
  --bg-color: var(--color-primary);

  display: flex;
  align-items: center;
  margin-bottom: 33px;
  position: relative;
  max-width: 100%;
  padding-top: 50px;

  ${breakpointsMedia(breakpoints.tabletWide)} {
    margin-bottom: 53px;
    width: 637px;
  }

  .index {
    position: absolute;
    z-index: 3;
    top: 7px;
    left: 16px;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      margin-right: 83px;
      position: relative;
    }

    div {
      font-size: 90px;
      font-weight: 700;
      line-height: 1;
      font-style: normal;
      font-family: ${FontFamily.Heading};
      display: block;

      ${breakpointsMedia(breakpoints.tabletWide)} {
        font-size: 210px;
      }
    }

    .main {
      color: var(--main-color);
    }

    .shadow {
      position: absolute;
      top: 5px;
      left: 5px;
      z-index: -1;
      color: var(--shadow-color);

      ${breakpointsMedia(breakpoints.tabletWide)} {
        top: 10px;
        left: 10px;
      }
    }
  }

  .content-wrap {
    position: relative;
    margin-right: 10px;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      margin-right: 0;
    }

    .inner {
      min-height: 205px;
      max-width: 100%;
      background-color: #ffffff;
      box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.03);
      display: flex;
      flex-direction: column;
      justify-content: center;

      z-index: 1;
      position: relative;
      padding: 66px 27px 27px;

      ${breakpointsMedia(breakpoints.tabletWide)} {
        padding: 41px;
        min-height: 300px;
        width: 418px;
        padding: 30px 27px 27px;
      }
    }

    .title {
      color: var(--color-text);
      font-size: 20px;
      font-weight: 500;
      line-height: 27px;
      margin-bottom: 8px;
    }

    .content {
      color: var(--color-text);
      font-size: 17px;
      line-height: 29px;

      p:first-child {
        margin-top: 0;
      }

      &:after {
        content: "";
        display: block;
        height: 1px;
        width: 114px;
        background-color: var(--color-primary);
        margin-top: 18px;
      }
    }

    .content:after,
    .backdrop {
      background-color: var(--bg-color);
    }

    .backdrop {
      z-index: 0;
    }
  }

  &.style-1 {
    ${breakpointsMedia(breakpoints.tabletWide)} {
      align-self: flex-start;
    }

    .index {
      .main {
        --main-color: #f7a624;
      }

      .shadow {
        --shadow-color: #db8f12;
      }
    }

    .content-wrap {
      .content:after,
      .backdrop {
        --bg-color: #db8f12;
      }
    }
  }

  &.style-2 {
    ${breakpointsMedia(breakpoints.tabletWide)} {
      align-self: center;
    }

    .index {
      .main {
        --main-color: var(--color-primary);
      }

      .shadow {
        --shadow-color: var(--color-primary-darkened);
      }
    }

    .content-wrap {
      .content:after,
      .backdrop {
        --bg-color: var(--color-primary-darkened);
      }
    }
  }

  &.style-3 {
    ${breakpointsMedia(breakpoints.tabletWide)} {
      align-self: flex-end;
    }

    .index {
      .main {
        --main-color: var(--color-secondary);
      }

      .shadow {
        --shadow-color: var(--color-secondary-darkened);
      }
    }

    .content-wrap {
      .content:after,
      .backdrop {
        --bg-color: var(--color-secondary-darkened);
      }
    }
  }
`

const ProcessModule = ({ styleIndex, indexNumber, title, content }) => {
  return (
    <ProcessModuleStyle
      data-aos-duration="1000"
      className={`style-${styleIndex}`}
    >
      <div className="" />
      <div className="index">
        <div className="main  " data-aos="up-left" data-aos-duration="1000">
          {indexNumber}
        </div>
        <div className="shadow">{indexNumber}</div>
      </div>
      <div className="content-wrap backdrop-bg">
        <div className="backdrop" />
        <div className="inner  " data-aos="up-left" data-aos-duration="1000">
          <div
            className="title  "
            data-aos="fade-up-left"
            data-aos-duration="1000"
            data-aos-delay="0"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <div
            className="content  "
            data-aos="fade-up-left"
            data-aos-duration="1000"
            data-aos-delay="200"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>
    </ProcessModuleStyle>
  )
}

ProcessModule.propTypes = {}

export default ProcessModule
