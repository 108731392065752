import { css } from "styled-components"

export const textShadowOffset = (
  main_colour,
  shadow_colour,
  offset = "0.08em"
) => {
  return css`
    color: ${main_colour};
    text-shadow: ${offset} ${offset} 0 ${shadow_colour};
  `
}
