import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import BgImage from "gatsby-background-image"
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api"

import googleMapsStyle from "../../configs/googleMapsStyle"
import { MapLocationStyle } from "@styles/organisms/Map"
import { convertToBgImage } from "gbimage-bridge"

//TODO: Make sure phone number WP shortcodes are rendered
const MapLocationModule = (props) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.GATSBY_GOOGLE_API_KEY
  })

  const [_map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(_map) {
    setMap(_map)
  }, [])

  const onUnmount = React.useCallback(function callback(_map) {
    setMap(null)
  }, [])

  const {
    phoneNumber,
    address,
    map,
    name,
    index,
    backgroundImage
  } = props

  const bgImage = convertToBgImage(backgroundImage.localFile?.childImageSharp.gatsbyImageData)

  const center = {
    lat: map.latitude,
    lng: map.longitude
  }

  const BgImageCon = bgImage ? BgImage : "div"

  return (
    <MapLocationStyle
      className={classNames("map-location", `location-${index}`)}
    >
      <div className="backdrop" />
      <div className="bg-wrap" data-aos="up-left" data-aos-duration="1000">
        <div className="inner-wrap">
          <BgImageCon
            Tag="div"
            {...bgImage}
            className="location-details"
          >
            <div className="location-overlay" />
            <div className="inner">
              <div className="name">{name}</div>
              <div className="address">
                <address>{address}</address>
                <span className="phone">{phoneNumber}</span>
              </div>
            </div>
          </BgImageCon>
          {isLoaded && (
            <div className="map-wrap">
              <GoogleMap
                center={center}

                zoom={12}
                options={{
                  zoom: 12,
                  zoomControl: false,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: false,
                  styles:googleMapsStyle,
                  panControl:false,
                  draggable:false
                }}
                onLoad={onLoad}
                onUnmount={onUnmount}
                mapContainerClassName="google-map"
              />
              <div className="pin">
                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas"
                     data-icon="map-marker-alt" className="svg-inline--fa fa-map-marker-alt fa-w-12" role="img"
                     viewBox="0 0 384 512">
                  <path fill="currentColor"
                        d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z" />
                </svg>
              </div>
            </div>
          )}
        </div>
      </div>
    </MapLocationStyle>
  )
}

MapLocationModule.propTypes = {
  index: PropTypes.number.isRequired,
  map: PropTypes.shape({
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired
  }),
  name: PropTypes.string.isRequired,
  address: PropTypes.string,
  phoneNumber: PropTypes.string,
  backgroundImage: PropTypes.object
}

export default MapLocationModule
